@import '../../styles/modules/fonts';
@import '../../styles/modules/mixins';
@import '../../styles/modules/variables';
@import '../../styles/modules/general';
.home-header {
    position: relative;
    min-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('https://images.unsplash.com/photo-1461695008884-244cb4543d74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1931&q=80');
    color: var(--outvoice-palette-common-white);
    color: var(--outvoice-palette-primary-main);

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        width: 50%;
        background-color: var(--outvoice-palette-common-white);
        z-index: 99;
        transform: skewX(45deg);
        transform-origin: 100% 100%;
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        right: 0;
        height: 20px;
        width: 50%;
        background-color: white;
        z-index: 99;
        transform: skewX(-45deg);
        transform-origin: 0 100%; 
    }

    &-box {
        max-width: 1200px;
        width: 100%;
        // background-color: var(--outvoice-palette-primary-dark);
        padding: 48px 64px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
}