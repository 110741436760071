@import '../../../styles/modules/fonts';
@import '../../../styles/modules/mixins';
@import '../../../styles/modules/variables';
@import '../../../styles/modules/general';
.page-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;

    &--default {
        background-color: var(--outvoice-palette-background-default);
    }

    &--primary {
        background-color: var(--outvoice-palette-primary-dark);
    }

    &--primary-gradient {
        background: linear-gradient(315deg, var(--outvoice-palette-primary-main) 0%, var(--outvoice-palette-secondary-main) 100%);
    }

    &--secondary {
        background-color: var(--outvoice-palette-secondary-dark);
    }

    &--secondary-gradient {
        background: linear-gradient(315deg, var(--outvoice-palette-secondary-light) 0%, var(--outvoice-palette-secondary-dark) 100%);
    }

    &-title {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    &-inner {
        // position: relative;
        display: flex;
        flex-direction: column;
        // margin: 0 auto;
        padding: 88px 24px;
        flex: 1 1 100%;
        overflow-x: hidden;
        // height: 100%;
        // width: 100%;
        // box-sizing: border-box;

        &--xs {
            // min-width: 320px;
            // max-width: 600px;
        }

        &--md {
            // min-width: 600px;
            // max-width: 900px;
        }

        &--lg {
            // min-width: 900px;
            // max-width: 1200px;
        }
    }
}