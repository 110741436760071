@import '../../../styles/modules/fonts';
@import '../../../styles/modules/mixins';
@import '../../../styles/modules/variables';
@import '../../../styles/modules/general';
.ov-drawer {
    width: 4rem;
    transition: width 0.25s ease!important;
    overflow-x: hidden;
    background-color: var(--outvoice-palette-primary-main);
    color: var(--outvoice-palette-primary-contrastText);
    fill: var(--outvoice-palette-primary-contrastText);

    a {
        all: unset;
    }

    &-logo {
        position: fixed;
        top: 0;
        left: 0;
        padding: 20px;
        border-bottom-right-radius: 16px;
        background-color: var(--outvoice-palette-primary-main);
        color: var(--outvoice-palette-primary-contrastText);
        z-index: 1201;
    }
    
    &-list {

        &-button {
            max-width: 20rem;
            width: 100%;
            transition: width 0.6s ease, background-color 0.22s ease;
            // overflow: hidden;

            &-min {
                max-width: 64px;

                .ov-drawer-list-label {
                    white-space: pre;
                    width: 100%;
                    // overflow: hidden;
                    text-overflow: clip;
                }
            }

            &--active, &:hover {
                background-color: var(--outvoice-palette-secondary-main)!important;
            }
        }

        &-icon {
            color: var(--outvoice-palette-primary-contrastText);
            fill: var(--outvoice-palette-primary-contrastText);
        }
    }
    
    &--open {
        width: 20rem;
    }
}