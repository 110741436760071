@import '../../styles/modules/fonts';
@import '../../styles/modules/mixins';
@import '../../styles/modules/variables';
@import '../../styles/modules/general';
.register {

    &-wrapper {
        display: flex;
        flex: 1;
        align-items: center;
    }

    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}