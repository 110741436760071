 @font-face {
    font-family: 'Roboto Slab';
    src: url('../../../public/font/RobotoSlab-Medium.woff2') format('woff2'),
        url('../../../public/font/RobotoSlab-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Sans';
    src: url('../../../public/font/PTSans-Regular.woff2') format('woff2'),
        url('../../../public/font/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
