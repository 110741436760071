@import '../../../styles/modules/fonts';
@import '../../../styles/modules/mixins';
@import '../../../styles/modules/variables';
@import '../../../styles/modules/general';
.login {
    &-card {
        max-width: 600px;
        width: 100%;
        padding: 24px;
        min-width: 320px;

        @media screen and (min-width: 728px) {
            min-width: 450px;    
        }
        
        &-content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }
}