body {
    // min-height: 100vh;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

#root {
    min-height: 100vh;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    display: flex;
    flex: 1 0 100%;
}